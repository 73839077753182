<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: ESM - EASY SCHOOL MANAGEMENT
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- ROW 1-->
    <div class="vx-row">
       
        <div class="vx-col w-full mb-base">
          <div class="vx-row" v-if="showByAdmin ==='admin'">
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.appointments || 0) )"
                :statisticTitle="$t('appointments')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="treatments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FolderIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.treatments || 0) )"
                :statisticTitle="$t('treatments_D')"/>
            </div>

            <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="patients()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.patients || 0) )"
                :statisticTitle="$t('patients_D')"  />
            </div>

            <div class="cursor-pointer  vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="practitioners()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.dentists || 0) )"
                :statisticTitle="$t('dentists')"  />
            </div>


            <div class="cursor-pointer vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="assurances()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserCheckIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.assurances || 0) )"
                :statisticTitle="$t('assurances_D')"/>
            </div>
            <div class="cursor-pointer vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="pharmacies()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.pharmacies || 0) )"
                :statisticTitle="$t('pharmacies_d')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/3" @click="bills_paid()"  >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.billsPaid || 0) )"
                :statisticTitle="$t('billsPaid')"/>
            </div>

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/3" @click="bills_not_paid()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.billNotPaid || 0) )"
                :statisticTitle="$t('billNotPaid')"  />
            </div>

            <div class="cursor-pointer  vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="thirdPartyPayments()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.thirdPartyPayments || 0) )"
                :statisticTitle="$t('thirdPartyPayments')"  />
            </div>

           <!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>
          <div class="vx-row" v-if="showByAdmin ==='dentist'">

           <!--  <vx-card ref="filterCard" :title="this.$t('filte_rendezVous')" class="user-list-filters mb-4 vx-col w-/12"  >
                    <div class="vx-row ">
                      <div class="w-full">
                        <v-select 
                        :options="appointmentChoises" 
                        :reduce="rep => rep.key" 
                        :clearable="true" 
                        label="name"
                        v-model="filter_appointment" 
                        class="w-full"/>
                      </div>
                    </div>

            </vx-card> -->
            <!-- <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="patients()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.patients || 0) )"
                :statisticTitle="$t('patients_D')"  />
            </div> -->

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.appointments || 0) )"
                :statisticTitle="$t('appointments')"/>
            </div>

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.pastdAppointments || 0) )"
                :statisticTitle="$t('pastdAppointments')"  />
            </div>

            <div v-if="filter_appointment===null" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="upComming_appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments')"/>
            </div>

            <div v-if="filter_appointment==='day'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_day()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_days')"/>
            </div>

            <div v-if="filter_appointment==='week'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_week()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_weeks')"/>
            </div>

            <div v-if="filter_appointment==='month'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_month()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_months')"/>
            </div>
            

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="treatments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FolderIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.treatments || 0) )"
                :statisticTitle="$t('treatments_D')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="prescriptions()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FilePlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.prescriptions || 0) )"
                :statisticTitle="$t('prescriptions_D')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="sickLeaves()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="NavigationIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.sickLeaves || 0) )"
                :statisticTitle="$t('sickLeaves_D')"/>
            </div>

            
           <!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>
          <div class="vx-row" v-if="showByAdmin ==='generalist'">

           <!--  <vx-card ref="filterCard" :title="this.$t('filte_rendezVous')" class="user-list-filters mb-4 vx-col w-/12"  >
                    <div class="vx-row ">
                      <div class="w-full">
                        <v-select 
                        :options="appointmentChoises" 
                        :reduce="rep => rep.key" 
                        :clearable="true" 
                        label="name"
                        v-model="filter_appointment" 
                        class="w-full"/>
                      </div>
                    </div>

            </vx-card> -->
            <!-- <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="patients()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.patients || 0) )"
                :statisticTitle="$t('patients_D')"  />
            </div> -->

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.appointments || 0) )"
                :statisticTitle="$t('appointments')"/>
            </div>

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.pastdAppointments || 0) )"
                :statisticTitle="$t('pastdAppointments')"  />
            </div>

            <div v-if="filter_appointment===null" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="upComming_appointments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments')"/>
            </div>

            <div v-if="filter_appointment==='day'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_day()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_days')"/>
            </div>

            <div v-if="filter_appointment==='week'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_week()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_weeks')"/>
            </div>

            <div v-if="filter_appointment==='month'" class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="appointments_month()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CalendarIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.upcomingApointments || 0) )"
                :statisticTitle="$t('upcomingApointments_months')"/>
            </div>
            

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="treatments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FolderIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.treatments || 0) )"
                :statisticTitle="$t('treatments_D')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="prescriptions()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="FilePlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.prescriptions || 0) )"
                :statisticTitle="$t('prescriptions_D')"/>
            </div>

            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="sickLeaves()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="NavigationIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.sickLeaves || 0) )"
                :statisticTitle="$t('sickLeaves_D')"/>
            </div>


<!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>
          <div class="vx-row" v-if="showByAdmin ==='accountant'">
          
            <div class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/3" @click="bills_paid()"  >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.billsPaid || 0) )"
                :statisticTitle="$t('billsPaid')"/>
            </div>

            <div class=" cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/3" @click="bills_not_paid()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.billNotPaid || 0) )"
                :statisticTitle="$t('billNotPaid')"  />
            </div>

            <div class="cursor-pointer  vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="thirdPartyPayments()">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ListIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.thirdPartyPayments || 0) )"
                :statisticTitle="$t('thirdPartyPayments')"  />
            </div>

           <!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>
        </div>
    </div> 

  </div>
</template>

<script>


import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from '@/views/ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'
import ChartjsBarChart from '/src/views_moozimed/statistiqueGraph/ChartjsBarChart'

export default {
  data () {
    return {
      showByAdmin: '',
      dashboardValue: false,
      showByContributors: false,
      variable_dashboard : '',
      analyticsData,
      appointmentChoises: [
        {
          key: 'day',
          name: 'Jour'
        },
        {
          key: 'week',
          name: 'Semaine'
        },
        {
          key: 'month',
          name: 'Mois'
        }
      
      ],
      filter_appointment:null
    }
  },

  components: {
    VueApexCharts,
    ChartjsBarChart,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  watch: {
    filter_appointment (value) {
      if (value === 'day') {
        this.$http.post('dashboard-filter/', { day: value })
          .then((response) => {
            this.variable_dashboard = response.data
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } else if (value === 'week') {
        this.$http.post('dashboard-filter/', { week: value })
          .then((response) => {
            this.variable_dashboard = response.data
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else if (value === 'month') {
        this.$http.post('dashboard-filter/', { month: value })
          .then((response) => {
            this.variable_dashboard = response.data
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.dashboard2()
      }
    }
  }, 
  methods: {
    pass_appointments () {
      this.$router.push('appointments_pass/').catch(() => {})
    },
    upComming_appointments () {
      this.$router.push('appointments_upcomming/').catch(() => {})
    },
    appointments_day () {
      this.$router.push('appointments_day/').catch(() => {})
    },
    appointments_week () {
      this.$router.push('appointments_week/').catch(() => {})
    },
    appointments_month () {
      this.$router.push('appointments_month/').catch(() => {})
    },
    bills_paid () {
      this.$router.push('bills_paid/').catch(() => {})
    },
    bills_not_paid () {
      this.$router.push('bills_not_paid/').catch(() => {})
    },
    thirdPartyPayments () {
      this.$router.push('bills_to_settle/').catch(() => {})
    },
    assurances () {
      this.$router.push('assurances/').catch(() => {})
    },
    pharmacies () {
      this.$router.push('pharmacies/').catch(() => {})
    },
    patients () {
      this.$router.push('patients/').catch(() => {})
    },
    treatments () {
      this.$router.push('treatments/').catch(() => {})
    },
    prescriptions () {
      this.$router.push('prescriptions/').catch(() => {})
    },
    sickLeaves () {
      this.$router.push('sickLeaves/').catch(() => {})
    },
    appointments () {
      this.$router.push('appointments_full/').catch(() => {})
    },
    practitioners () {
      this.$router.push('practitioners/').catch(() => {})
    },
    dashboard () {
      this.$vs.loading()
      this.$http.get('dashboard-admin/')
        .then((response) => {
          this.variable_dashboard = response.data
          this.$vs.loading.close()
        })
    },
    dashboard2 () {
      this.$vs.loading()
      this.$http.get('dashboard-dentist/')
        .then((response) => {
          this.variable_dashboard = response.data
          this.$vs.loading.close()
        })
    },
    dashboardRefrech () {
      this.$http.get('dashboard-admin/')
        .then((response) => {
          this.variable_dashboard = response.data
        })
    },
    dashboard2Refrech () {
      this.$http.get('dashboard-dentist/')
        .then((response) => {
          this.variable_dashboard = response.data
        })
    }
  },
  created () {
    const user_role = JSON.parse(localStorage.getItem('userInfo')).role.name 
    if (user_role === 'admin') {
      this.showByAdmin = 'admin'
      this.dashboard()
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech()
        }.bind(this), 60000)
    } else if (user_role === 'slaveadmin') {
      this.dashboard()
      this.showByAdmin = 'admin'
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech()
        }.bind(this), 60000)
    } else if (user_role === 'dentist') {
      this.dashboard2()
      this.showByAdmin = 'dentist'
      this.counterInterval =  setInterval(
        function () {
          this.dashboard2Refrech()
        }.bind(this), 60000)
    } else if (user_role === 'generalist') {
      this.dashboard2()
      this.showByAdmin = 'generalist'
      this.counterInterval =  setInterval(
        function () {
          this.dashboard2Refrech()
        }.bind(this), 60000)
    } else if (user_role === 'accountant') {
      this.dashboard()
      this.showByAdmin = 'accountant'
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech()
        }.bind(this), 60000)
    }
    document.title = 'MooziMed'
  },
  destroyed () {
    clearInterval(this.counterInterval)
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
